import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import TOTPForm from "./TOTPForm";
import ShareSecret from "./ShareSecret";
import ShareFile from "./ShareFile";
import UploadSecret from "./UploadSecret";
import UploadFileForm from "./UploadFileForm";
import NoNo from './NoNo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'

const MainComponent = (props) => {

    const [validTotp, setVaslidTotp] = useState(false);
    const [session, setSession] = useState([]);
    const [clientToken, setClientToken] = useState();
    const [module, setModule] = useState();
    const [shareTOTP, setShareTOTP] = useState(true);

    useEffect(() => {
        console.log("[MAIN] URL = " + window.location.href);

        // we got a share secret request where no totp is required.
        if (window.location.href.indexOf('share/n-') > 0) {
            // console.log("[MAIN] SECRET - FOUND NO TOTP IN URL.. Assume we DO share public.");
            setShareTOTP(false);
        }


        // we got a share secret request where no totp is required.
        if (window.location.href.indexOf('f/n-') > 0) {
            // console.log("[MAIN] FILES - FOUND NO TOTP IN URL.. Assume we DO share public.");
            setShareTOTP(false);
        }
    },[]);

    function setUserSession(s, ct) {
        // console.log("[MAIN] 1 Got Session " + s + " and token " + ct + " and valid totp " + validTotp);
        setSession(s);
        setClientToken(ct)
        setVaslidTotp(true);
    }

    function passwordClicked() {
        // console.log("[MAIN]You want password?");
        setModule("PASSWORD");
    }

    function filesClicked() {
        // console.log("[MAIN]You want files?");
        setModule("FILES");
    }

    function show(name) {
        // console.log("[MAIN] show module " + name);
        setModule(name);
    }

    return (!validTotp && shareTOTP ?
        (
            <TOTPForm setUserSession={setUserSession}></TOTPForm>
        ) :
        (
            <Router>
                <Switch>
                    <Route path="/share">
                        <ShareSecret session={session} clientToken={clientToken}></ShareSecret>
                    </Route>

                    <Route path="/f">
                        <ShareFile session={session} clientToken={clientToken}></ShareFile>
                    </Route>

                    <Route path="/password">
                        <Container fluid className="pt-1">
                            <Row className="mt-1">
                                <Col md="1"></Col>
                                <Col md="10" className="pt-3 pb-2 main text-center">
                                    <h1 className="header ">Share a secret, securely!</h1>
                                </Col>
                                <Col md="1"></Col>
                            </Row>
                        </Container>
                        <UploadSecret session={session} clientToken={clientToken}></UploadSecret>
                    </Route>

                    <Route path="/files">
                        <Container fluid className="pt-1">
                            <Row className="mt-1">
                                <Col md="1"></Col>
                                <Col md="10" className="pt-3 pb-2 main text-center">
                                    <h1 className="header ">Share a file, securely!</h1>
                                </Col>
                                <Col md="1"></Col>
                            </Row>
                        </Container>
                        <UploadFileForm session={session} clientToken={clientToken}></UploadFileForm>
                    </Route>

                    <Route path="/nono">
                        <NoNo></NoNo>
                    </Route>

                    <Route path="/error">
                        <NoNo></NoNo>
                    </Route>

                    <Route path="/">
                        {module === "PASSWORD" ?
                            <Container fluid className="pt-1">
                                <Row className="mt-1">
                                    <Col sd="1" className="pt-3 pb-2 main "></Col>
                                    <Col sd="4" className="pt-3 pb-2 main text-center" onClick={passwordClicked}>
                                        <FontAwesomeIcon icon={faUnlockAlt} size="2x" />
                                        <h4>Secrets</h4>
                                    </Col>
                                    <Col sd="2" className="pt-3 pb-2 main "></Col>
                                    <Col sd="4" className="pt-3 pb-2 main text-center" onClick={filesClicked}>
                                        <FontAwesomeIcon icon={faFolderOpen} color="#007bff" size="1x" />
                                        <h4>Files</h4>
                                    </Col>
                                    <Col sd="1" className="pt-3 pb-2 main "></Col>
                                </Row>

                                <UploadSecret session={session} clientToken={clientToken} showModule={show}></UploadSecret>
                            </Container>
                            : module === "FILES" ?
                                <Container fluid className="pt-1">
                                    <Row className="mt-1">
                                        <Col sd="1" className="pt-3 pb-2 main "></Col>
                                        <Col sd="4" className="pt-3 pb-2 main text-center" onClick={passwordClicked}>
                                            <FontAwesomeIcon icon={faUnlockAlt} color="#007bff" size="1x" />
                                            <h4>Secrets</h4>
                                        </Col>
                                        <Col sd="2" className="pt-3 pb-2 main "></Col>
                                        <Col sd="4" className="pt-3 pb-2 main text-center" onClick={filesClicked}>
                                            <FontAwesomeIcon icon={faFolderOpen} size="2x" />
                                            <h4>Files</h4>
                                        </Col>
                                        <Col sd="1" className="pt-3 pb-2 main "></Col>
                                    </Row>
                                    <UploadFileForm session={session} clientToken={clientToken} showModule={show}></UploadFileForm>
                                </Container>
                                :
                                <Container fluid className="pt-1">
                                    <Row className="mt-1">
                                        <Col md="1"></Col>
                                        <Col md="10" className="pt-3 pb-2 main text-center">
                                            <h1 className="header ">What do you want to share?</h1>
                                        </Col>
                                        <Col md="1"></Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col md="2"></Col>
                                        <Col md="3" className="pt-3 pb-2 main text-center" onClick={passwordClicked}>
                                            <FontAwesomeIcon icon={faUnlockAlt} color="#007bff" size="3x" />
                                            <h4>Secrets</h4>
                                            Hier kann man Passwörter und Secrets bis zu 2000 Zeichen teilen. Die
                                            Verfügbarkeit ist auf einen Zugriff begrenzt und ist zeitlich beschränkt.
                                            Der Zugriff kann optional auch via TOTP erfolgen.
                                        </Col>
                                        <Col md="1"></Col>
                                        <Col md="3" className="pt-3 pb-2 main text-center" onClick={filesClicked}>
                                            <FontAwesomeIcon icon={faFolderOpen} color="#007bff" size="3x" />
                                            <h4>Files</h4>
                                            Hier kann man einzelne Dateien mit bis zu 100GB Dateigröße teilen.  Die
                                            Verfügbarkeit ist auf einen Zugriff begrenzt und ist zeitlich beschränkt. 
                                            Der Zugriff kann optional auch via TOTP erfolgen.
                                        </Col>
                                        <Col md="2"></Col>
                                    </Row>
                                    
                                </Container>
                        }
                    </Route>

                </Switch>
            </Router>
        )
    );
}


export default MainComponent;