import React, { useEffect, useState } from 'react';
import { Container  } from 'react-bootstrap';


// http://localhost:3000/share/7qHqtKEUQRuHsOWfSG9xpzup2VsXTHLZ1TyXOdJamhSZ9TWQveuNN207XMWbA2KP
const ShareFile = (props) => {

    const [checkingSecret, setCheckingSecret] = useState(false);
    const [checkedSecret, setCheckedSecret] = useState(false);
    const [validFile, setValidFile] = useState(false);
    const [status, setStatus] = useState("NONE");
  
    useEffect(() => {
        if (status === "NONE") {
            // console.log("[SHARE-FILE] NONE - No Session (NONE)")
            setStatus("AUTH")

        }
        else if (status === "AUTH") {
            // console.log("[SHARE-FILE]  Checked Secret " + checkedSecret);
            // console.log("[SHARE-FILE]  validFile  " + validFile);
      
            if (!checkingSecret) {
                setCheckingSecret(true);
                var path = window.location.pathname;
                // console.log("[SHARE-FILE] Path " + path);
                //console.log(path.lastIndexOf('/'));
                //console.log(path.length);

                path = path.lastIndexOf('/') === path.length - 1 ? path.substring(0, path.length - 1) : path;
                var token = path.substring(path.lastIndexOf('/') + 1);
                // console.log("[SHARE-FILE] New Secret Request for token " + token);
                // console.log("[SHARE-FILE] Location " + window.location.pathname);
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'mercatis',
                        'Token': props.clientToken,
                        'Session':props.session,
                    }//,
                    //body: JSON.stringify({ secret : secret })
                };
                fetch('/file/' + token, requestOptions)
                    .then(response => {
                        // console.log("[SHARE-FILE] promise 1");
                        const sc = response.status;
                        const data = response.json();
                        return Promise.all([sc, data]);
                    })
                    .catch(error => {  console.log(error); return Promise.all([555, ""]) })
                    .then(promise => {
                        var statusCode = promise[0];
                        var data = promise[1];
                        // console.log("[SHARE-FILE] promise 2");
                        // console.log(statusCode);
                        // console.log("[SHARE-FILE] promise 2");
                        // console.log(data);


                        if (statusCode === 200 && data ) {
                            console.log("[SHARE-FILE] All good will start download");
                            setCheckedSecret(true);
                            setValidFile(true);
                            window.location.replace(data);
                        } else {
                            // console.log("[SHARE-FILE] Got no url and statusCode is " + statusCode + " assume a bad guy - go away");
                            setValidFile(false);
                            window.location.replace('/error/nono.html');
                        }
                        setCheckedSecret(true);
                    })
                    .catch(error => {
                        setValidFile(false);
                        setCheckedSecret(true);
                        console.error(error);
                        return { name: "network error", description: "" };
                    });
            }
            if ((checkedSecret && !validFile) ) {
               // console.log("[SHARE-FILE] go away");
                window.location.replace('/error/nono.html');
            }
        }
    }
    ,[status,checkedSecret,validFile,checkingSecret, props.clientToken, props.session]);


    return ( checkedSecret && validFile )? (
        <div className="ShareFile pt-5">

            <Container className="main mt-5">
                <div className="text-center pt-5 pb-5">
                    <h1 className="header ">Secure File sharing</h1>
                    <h5>Download is starting</h5>
                </div>
            </Container>
        </div>
    ) : (<div>... verifying your request ...</div>);
}

export default ShareFile;
