
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from "react";

import MainComponent from './MainComponent';


function App() {
  return ( <MainComponent></MainComponent>);
  
}

export default App;
