import React, { useEffect, useState } from 'react';
import { Container, Button, InputGroup,  OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const ShareSecret = (props) => {

    const [secret, setSecret] = useState([]);
    const [visible, setVisible] = useState(false);
    const [timesUp, setTimesUp] = useState(false);
    const [checkingSecret, setCheckingSecret] = useState(false);
    const [checkedSecret, setCheckedSecret] = useState(false);
    const [validSecret, setValidSecret] = useState(false);
    const [status, setStatus] = useState("NONE");

    
 

    useEffect(() => {
        if (status === "NONE") {
            // console.log("[SHARE] NONE - No Session (NONE)")
            setStatus("AUTH")

        }
        else if (status === "AUTH") {
            // console.log("[SHARE]  Checked Secret " + checkedSecret);
            // console.log("[SHARE]  ValidSecret  " + validSecret);
            // console.log("[SHARE]  timesUp  " + timesUp);

            if (!checkingSecret) {
                setCheckingSecret(true);
                var path = window.location.pathname;
                // console.log("[SHARE] Path " + path);
                //console.log(path.lastIndexOf('/'));
                //console.log(path.length);

                path = path.lastIndexOf('/') === path.length - 1 ? path.substring(0, path.length - 1) : path;
                var token = path.substring(path.lastIndexOf('/') + 1);
                // console.log("[SHARE] New Secret Request for token " + token);
                // console.log("[SHARE] Location " + window.location.pathname);
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'mercatis',
                        'Token': props.clientToken,
                        'Session':props.session,
                    }//,
                    //body: JSON.stringify({ secret : secret })
                };
                fetch('/pw/' + token, requestOptions)
                    .then(response => {
                        const sc = response.status;
                        const data = response.json();
                        return Promise.all([sc, data]);
                    })
                    .catch(error => { console.log(error); return Promise.all([555, ""]) })
                    .then(promise => {
                        var statusCode = promise[0];
                        var data = promise[1];
                        if (statusCode === 200 && data && data.secret) {
                            setSecret(data.secret);
                            setValidSecret(true);
                            const timer = setTimeout(() => { setTimesUp(true); console.log("[SHARE] times up"); }, 60000);
                            console.log("[SHARE] All good will vanish in 60 seconds " + timer);
                        } else {
                            // console.log("[SHARE] Got no result and statusCode is " + statusCode + " assume a bad guy - go away");
                            setValidSecret(false);
                            window.location.replace('/error/nono.html');
                        }
                        setCheckedSecret(true);
                    })
                    .catch(error => {
                        setValidSecret(false);
                        setCheckedSecret(true);
                        console.error(error);
                        return { name: "network error", description: "" };
                    });
            }
            if ((checkedSecret && !validSecret) || timesUp) {
                // console.log("[SHARE] go away");
                window.location.replace('/error/nono.html');
            }
        }
    },[status, checkingSecret, checkedSecret, validSecret, timesUp, props.clientToken, props.session]
    );

    // const handleOTP = (otp) => {
    //     // console.log(otp)
    //     setOTP(otp);
    //     if (otp.length > 5) {
    //         setStatus("VERIFY");
    //     }
    // }
    const showHide = (e) => {
        // console.log("[SHARE]  OLD " + visible);
        setVisible(!visible);
        // console.log("[SHARE]  NEW " + visible);
    };

    function copyToClipboard(e) {
        navigator.clipboard.writeText(secret);
        // console.log('Copied to clipboard ' + secret);
    };

    return ( checkedSecret && validSecret )? (
        <div className="ShareSecret pt-5">

            <Container className="main mt-5">
                <div className="text-center pt-5 pb-5">
                    <h1 className="header ">Secure Shared Secret</h1>
                    <h5>You've got 60s to copy the secret, take your time ;-)</h5>
                    {(timesUp || !validSecret) ?
                        <div><span>redirecting to nono</span>
                        </div>
                        :
                        <div className="text-center">
                            {visible ? <div>{secret}</div> : <div>**********</div>}
                            <InputGroup className="justify-content-center" >

                                <OverlayTrigger
                                    key="key"
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-1">
                                            <b>Show / Hide</b><br />Toggle visibility here.
                                        </Tooltip>
                                    }
                                >
                                    <Button onClick={showHide} variant="secondary">
                                        {visible ?
                                            <FontAwesomeIcon icon={faEye} /> :
                                            <FontAwesomeIcon icon={faEyeSlash} />}
                                    </Button>
                                </OverlayTrigger>

                                <InputGroup.Append>
                                    <Button onClick={copyToClipboard} variant="primary">Copy shared secret to clipboard</Button>
                                </InputGroup.Append>
                            </InputGroup >
                        </div>
                    }
                </div>
            </Container>
        </div>
    ) : (<div>... verifying your request ...</div>);
}

export default ShareSecret;
