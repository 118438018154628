import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import uuid from 'uuid/v4';
import OtpInput from "react-otp-input";

const TOTPForm = (props) => {

    const [status, setStatus] = useState("NONE");
    const [otp, setOTP] = useState([]);
    const [error, setError] = useState();
    const [sessionUuid] = useState(uuid());

    function checkOTP(otp) {
        // console.log("[TOTP] VERIFY - OTP with " + otp);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'mercatis',
                'Token': sessionUuid,
                'OTP': otp
            }
        };
        fetch('/totp', requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                if (data.verify === "ok") {
                    props.setUserSession(data.session, data.token);
                    // console.log("[TOTP] VERIFY - OTP Authentication succeeded " + data.token + " / " + data.session);
                }
                else {
                    // console.log("[TOTP] VERIFY - OTP Authentication failing ...");
                    // setOTP("");
                    setStatus("NONE");
                    setError("Verification failed");
                    // console.log("[TOTP] VERIFY - OTP Authentication failed");
                }
            });
    }


    const handleOTP = (otp) => {
        // console.log(otp)
        setOTP(otp);
        if (otp.length > 5) {
            setStatus("VERIFY");
            checkOTP(otp);
        }
    }


    return status === "NONE" ? (
        <Container fluid className="pt-5">
            <Row className="mt-5">
                <Col md="1">    </Col>
                <Col md="10" className="d-flex justify-content-center">
                    <h1>Enter Access Code</h1>
                </Col>
                <Col md="1"></Col>
            </Row>
            <Row className="mt-5">
                <Col md="1"></Col>
                <Col md="10" className="d-flex justify-content-center">
                    <OtpInput className="OTP" shouldAutoFocus
                        value={otp}
                        onChange={handleOTP}
                        numInputs={6}
                        separator={<h1>-</h1>}
                    />
                </Col>
                <Col md="1"></Col>
            </Row>
            <Row className="mt-5">
                <Col md="1">    </Col>
                <Col md="10" className="d-flex justify-content-center">
                    <h3>{error}</h3>
                </Col>
                <Col md="1"></Col>

            </Row>

        </Container >
    ) : status === "VERIFY" ? (
        <Container fluid className="pt-5">
            <Row className="mt-5">
                <Col md="1"></Col>
                <Col md="10" className="d-flex justify-content-center">
                    <h1>OTP Code</h1>

                </Col>
                <Col md="1"></Col>s
            </Row>
            <Row className="mt-5">
                <Col md="1"></Col>
                <Col md="10" className="d-flex justify-content-center">
                    <h1>Verifying....</h1>
                </Col>
                <Col md="1"></Col>
            </Row>

        </Container >
    ) :
        (
            <div>
                Something went wrong. {status}
            </div>
        );

}

export default TOTPForm;