import React, { useState } from 'react';
import { Container, Row, Col, Button, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InputGroupWithExtras from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ConfigData from './config.json';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons'


const UploadForm = (props) => {

    const [secret, setSecret] = useState([]);
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [ttl, setTTL] = useState(3600);
    const [totp, setTOTP] = useState(false);
    const [status, setStatus] = useState("NEW");
    const [url, setUrl] = useState([]);
    const [inputType, setInputType] = useState("password");



    const handleSubmit = (e) => {
        // console.log("[UF] Sending secret=" + secret);
        setStatus("SEND");
        // POST request usin        g fetch with set headers
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'mercatis',
                'Token': props.clientToken,
                'Session': props.session,
                'Secret': secret,
                'TOTPRequired': totp,
                'TTL': ttl
            },
            body: JSON.stringify({ secret: secret })
        };
        fetch('/store', requestOptions)
            .then(response => response.json())
            .then(data => {
                // console.log("[UF] Response " + JSON.stringify(data));
                // console.log(data);
                if (data.success !== "ok") {
                    console.log("[UF] server side does not like the client, go away");
                    window.location.replace('/error/nono.html');
                }
                setUrl(ConfigData['host-prefix'] + data.token);
                setStatus("DONE");
            });
        //console.log(e);
    };

    const handleChange = (e) => {
        var val = e.target.value;
        setSecret(val);

        if (val.length > 1) {
            setSubmitBtnDisabled(false);
        }
        else {
            setSubmitBtnDisabled(true);
        }
    };


    const handleTTLChange = (e) => {
        setTTL(e.target.value);
    };

    const handleNeedTOTPChange = (e) => {
        setTOTP(e.target.value === "yes")
    }

    const showHide = (e) => {
        if (inputType === "password") {
            setInputType("text");
        } else {
            setInputType("password");
        }
    };
    const handleNew = (e) => {
        setStatus("NEW");
        setSecret("");
        setSubmitBtnDisabled(true);
    };

    const handleFile = (e) => {
        props.showModule("FILES");
    }

    function copyLinkToClipboard(e) {

        var text = url;
        navigator.clipboard.writeText(text);
        // console.log('Copied Link to clipboard ' + text);
    };

    function copyToClipboard(e) {

        var text = "# # # # # #  mercatis secure sharing - Download Link # # # # # # \n\n"
        + url + "\n\n"
        + "Infos zum Sharing: " 
        + "Gültigkeit: " + ttl + "s / " 
        + "TOTP Authentifierung: " + totp + "\n";
        navigator.clipboard.writeText(text);
        // console.log('Copied to clipboard ' + text);
    };


    return status === "NEW" ? (
        <Container fluid className="pt-3">
            <Row className="mt-3">
                <Col md="1"></Col>
                <Col md="10" className="pt-3 pb-2 main text-center">
                <FontAwesomeIcon icon={faUnlockAlt} size="3x" />
                    <h1 className="header ">Secure Secret Sharing</h1>
                </Col>
                <Col md="1"></Col>
            </Row>
            <Row >
                <Col md="1"></Col>
                <Col md="10" className="main text-center">
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <OverlayTrigger
                                key="key"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-1">
                                        <b>Show / Hide</b><br />Toggle visibility here.
                                </Tooltip>
                                }
                            >
                                <Button onClick={showHide} variant="secondary">
                                    {inputType === "password" ?
                                        <FontAwesomeIcon icon={faEye} /> :
                                        <FontAwesomeIcon icon={faEyeSlash} />}
                                </Button>
                            </OverlayTrigger>
                        </InputGroup.Prepend>

                        <OverlayTrigger
                            key="key"
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-1">
                                    <b>Secret to share</b><br />Type your secret here. It may have up to 2.000 characters.
                                </Tooltip>
                            }
                        >
                            <FormControl
                                placeholder="Enter your secret here"
                                onChange={handleChange}
                                onPaste={handleChange}
                                type={inputType}
                                maxLength="2048"
                                //  as="textarea" rows={1} --> password does not work on textarea
                                aria-label="Secret"
                            />
                        </OverlayTrigger>
                        <InputGroup.Append>
                            <OverlayTrigger
                                key="key"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-">
                                        <b>TOTP</b><br />Need mercatis TOTP to access shared password. No for customers.
                                </Tooltip>
                                }
                            >
                                <FormControl as="select" defaultValue="3600" onChange={handleNeedTOTPChange} data-width="140px" >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </FormControl>
                            </OverlayTrigger>
                        </InputGroup.Append>
                        <InputGroup.Append>
                            <OverlayTrigger
                                key="key"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-1">
                                        <b>TTL</b><br />Time to live for secret link.
                                </Tooltip>
                                }
                            >
                                <FormControl as="select" defaultValue="3600" onChange={handleTTLChange} data-width="140px" >
                                    <option value="5">5s</option>
                                    <option value="30">30s</option>
                                    <option value="300">5min</option>
                                    <option value="3600">1h</option>
                                    <option value="86400">24h</option>
                                    <option value="604800">7Days</option>
                                </FormControl>
                            </OverlayTrigger>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col md="1"></Col>
            </Row>
            <Row>
                <Col md="1"></Col>
                <Col md="10" className="pb-3 main">
                    <InputGroupWithExtras className="justify-content-end" >
                        <Button onClick={handleSubmit} disabled={submitBtnDisabled} variant="primary">Create Share Link</Button>
                    </InputGroupWithExtras>
                </Col>
                <Col md="1"></Col>
            </Row>

        </Container >
    )
        : status === "DONE" ? (

            <Container fluid className="pt-3">
                <Row className="mt-3">
                    <Col md="1"></Col>
                    <Col md="10" className="pt-3 pb-3 pb-2 main text-center">
                        <h1 className="header ">Secure Secret Sharing</h1>
                        <h5>Share the link with others, remember it will work only once in the entered time to live.</h5>
                    </Col>
                    <Col md="1"></Col>
                </Row>
                <Row >
                    <Col md="1"></Col>
                    <Col md="10" className="pb-3 main text-center">
                        <InputGroup className="justify-content-center" >
                        <InputGroup.Append>
                                <Button onClick={copyLinkToClipboard} variant="primary">Copy Link only to Clipboard</Button>
                            </InputGroup.Append>
                        </InputGroup >
                        <h5>&nbsp;</h5>
                        
                         <InputGroup className="justify-content-center" >
                            <InputGroup.Append>
                                <Button onClick={copyToClipboard} size="sm" variant="secondary">Copy Text to Clipboard</Button>
                            </InputGroup.Append>
                        </InputGroup >
                        <h5>&nbsp;</h5>
                  

                    </Col>
                    <Col md="1"></Col>
                </Row>
                <Row >
                    <Col md="1"></Col>
                    <Col sd="5" className="pb-3 main text-center">
                        <InputGroup className="justify-content-start">
                            <InputGroup.Append>
                                <Button onClick={handleFile} variant="light">Share a file</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    <Col sd="5" className="pb-3 main text-center">
                        <InputGroup className="justify-content-end">
                            <InputGroup.Append>
                                <Button onClick={handleNew} variant="secondary">Share another secret</Button>
                            </InputGroup.Append>
                        </InputGroup>

                    </Col>
                    <Col md="1"></Col>
                </Row>

            </Container >


        ) : status === "SEND" ? (
            <div>
                Storing data.
            </div>
        ) :
            (
                <div>
                    Something went wrong. {status}
                </div>
            );

}

export default UploadForm;