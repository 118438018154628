import React, {  useState } from 'react';
import { Container, Row, Col, Button, InputGroup, ProgressBar, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dropzone from 'react-dropzone' // https://react-dropzone.js.org/
import ConfigData from './config.json';
import axiosInstance from "./utils/axios"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons'

const UploadFileForm = (props) => {

    const [status, setStatus] = useState("NEW");
    const [url, setUrl] = useState([]);
    const [ttl, setTTL] = useState(3600);
    const [totp, setTOTP] = useState(false);
    const [filename, setFileName] = useState();
    const [gotFile, setGotFile] = useState(false);
    const [progress, setProgress] = useState()
    const [uploadDone, setUploadDone] = useState(false);



    function onDrop(acceptedFiles) {

        // console.log(acceptedFiles[0]);
        // console.log(acceptedFiles[0].name);

        setFileName(acceptedFiles[0].name);
        setGotFile(true);
        const formData = new FormData();
        formData.append('File', acceptedFiles[0]);
        axiosInstance.post("upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'mercatis',
                'Token': props.clientToken,
                'Session': props.session,
                'TOTPRequired': totp,
                'TTL': ttl
            },
            onUploadProgress: data => {
                console.log(".");
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        }).then(res => {
            // console.log(res);
            setUploadDone(true);
            setProgress();
            setUrl(ConfigData['host-prefix-file'] + res.data);
        }).catch(err =>  {
             console.log(err);
             window.location.replace('/error/nono.html');
            })

    };


    const handleSubmit = (e) => {
        // console.log("Sharing file");
        setStatus("COPY");
    };

    const setSubmitBtnDisabled = (e) => {
        // console.log("Sharing file");

    };

    const handleNew = (e) => {
        setStatus("NEW");
        setFileName("");
        setGotFile(false);
        setUploadDone(false);
        setSubmitBtnDisabled(true);
    };

    const handleTTLChange = (e) => {
        setTTL(e.target.value);
    };

    const handleNeedTOTPChange = (e) => {
        // console.log("[UFF] " + e.target.value);
        setTOTP(e.target.value === "yes")
    }

    const handleSecret = (e) => {
        props.showModule("PASSWORD");
    }

    function copyLinkToClipboard(e) {

        var text = url;
        navigator.clipboard.writeText(text);
        // console.log('Copied Link to clipboard ' + text);
    };

    function copyToClipboard(e) {

        var text = "# # # # # #  mercatis secure sharing - Download Link # # # # # # \n\n"
        + url + "\n\n"
        + "Infos zum Sharing: " 
        + "Gültigkeit: " + ttl + "s / " 
        + "TOTP Authentifierung: " + totp + "\n";
        navigator.clipboard.writeText(text);
        // console.log('Copied to clipboard ' + text);
    };



    return status === "NEW" ? (
        <Container fluid className="pt-3">
            <Row className="mt-3">
                <Col md="1"></Col>
                <Col md="10" className="pt-3 pb-2 main text-center">
                    <FontAwesomeIcon icon={faFolderOpen} size="3x" />
                    <h1 className="header ">Secure File Sharing</h1>
                </Col>
                <Col sd="1"></Col>
            </Row>
            <Row className="">
                <Col md="1"></Col>
                <Col sd="2" className="main"></Col>
                <Col sd="6" className="main text-center ">

                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <OverlayTrigger
                                key="key"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-">
                                        <b>TOTP</b><br />Need mercatis TOTP to access shared password. No for customers.
                                </Tooltip>
                                }
                            >
                                <FormControl as="select" defaultValue="3600" onChange={handleNeedTOTPChange} data-width="140px" >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </FormControl>
                            </OverlayTrigger>
                        </InputGroup.Prepend>
                        <InputGroup.Append>
                            <OverlayTrigger
                                key="key"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-1">
                                        <b>TTL</b><br />Time to live for secret link.
                                </Tooltip>
                                }
                            >
                                <FormControl as="select" defaultValue="3600" onChange={handleTTLChange} data-width="140px" >
                                    <option value="5">5s</option>
                                    <option value="30">30s</option>
                                    <option value="300">5min</option>
                                    <option value="3600">1h</option>
                                    <option value="86400">24h</option>
                                    <option value="604800">7Days</option>
                                </FormControl>
                            </OverlayTrigger>
                        </InputGroup.Append>
                    </InputGroup>



                </Col>
                <Col sd="2" className="main">  </Col>
                <Col md="1"></Col>
            </Row>
            <Row className="">
                <Col md="1"></Col>
                <Col md="10" className="main text-center ">
                    {progress &&
                        <div className="dropzone-progress"><ProgressBar now={progress} label={`${progress}%`} /></div>
                    }
                    {!gotFile &&
                        <Dropzone onDrop={onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p className="dropzone">Drag 'n' drop a single file here, or click to select file ..</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>}
                    {uploadDone && (
                        <div>
                            <div className="dropzone-done">
                                <h4>Upload done.</h4>
                        You may share the file <span className="fileName">{filename}</span> now.

                    </div>
                            <div className=" text-center float-right">

                                <InputGroup className="mb-3">

                                    <InputGroup.Append>
                                        <Button onClick={handleSubmit} variant="primary">Create Share Link</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </div>)}
                </Col>
                <Col md="1">

                </Col>
            </Row>


        </Container >
    )



        : status === "SEND" ? (
            <div>
                Storing data.
            </div>
        ) : status === "COPY" ? (

            <Container fluid className="pt-3">
                <Row>
                    <Col md="1"></Col>
                    <Col md="10" className="pt-3 pb-3 pb-2 main text-center">
                        <h1 className="header ">Secure File Sharing</h1>
                        <h5>Share the link with others, remember it will work only one time. The file will be deleted after the download or after the set time to live.</h5>
                    </Col>
                    <Col md="1"></Col>
                </Row>
                <Row>
                    <Col md="1"></Col>
                    <Col md="10" className="pb-3 main text-center">
                    <InputGroup className="justify-content-center" >
                            <InputGroup.Append>
                                <Button onClick={copyLinkToClipboard} variant="primary">Copy Link only to Clipboard</Button>
                            </InputGroup.Append>
                        </InputGroup >
                        <h5>&nbsp;</h5>
                        
                         <InputGroup className="justify-content-center" >
                            <InputGroup.Append>
                                <Button onClick={copyToClipboard} size="sm" variant="secondary">Copy Text to Clipboard</Button>
                            </InputGroup.Append>
                        </InputGroup >
                        <h5>&nbsp;</h5>
                  

                    </Col>
                    <Col md="1"></Col>
                </Row>
                <Row>
                    <Col md="1"></Col>
                    <Col sd="5" className="pb-3 main text-center">
                        <InputGroup className="justify-content-start">
                            <InputGroup.Append>
                                <Button onClick={handleSecret} variant="light">Share a secret</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>

                    <Col sd="5" className="pb-3 main text-center">
                        <InputGroup className="justify-content-end">
                            <InputGroup.Append>
                                <Button onClick={handleNew} variant="secondary">Share another file</Button>
                            </InputGroup.Append>
                        </InputGroup>

                    </Col>
                    <Col md="1"></Col>
                </Row>

            </Container >
        ) :
            (
                <div>
                    Something went wrong. {status}
                </div>
            );

}

export default UploadFileForm;